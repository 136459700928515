import { useEffect } from 'react';
import withRouter from '../../../utils/withRouter';
import useTheme from '../../../hooks/useTheme';
import { SimpleSelect } from '../../../components/generic/SimpleSelect';
import { getFromLS, saveToLS } from '../../../utils/localStore/localStore';
import useToast from '../../../hooks/useToast';

//Theme Selectors
import { colorMap, styleMap, modeMap } from '../../../styles/themeMaps';
import { FlexBox } from '../../../components/generic/Layout';

import styled from 'styled-components';
import NumericInput from '../../../components/numeric/NumericInput';
import { SwitchExample } from './Bootstrap';
import { TextLine } from '../../../components/generic/Layout';

import * as RiIcons from 'react-icons/ri'
import ListView from '../../../components/listView/ListView';
import useTranslation from '../../../hooks/useTranslation';

import MobileNavBar from '../../../components/mobileNav/MobileNavBar';
import Loader from '../../../components/generic/Loader';
import IconButton from '../../../components/generic/IconButton';
import { Button } from '../../../components/generic/Core';
import ClientVersion from '../../../Version';
import Reload from '../../../components/header/Reload';

const homeIcon = <RiIcons.RiHome2Fill />
const profileIcon = <RiIcons.RiUserSettingsFill />
const contactIcon = <RiIcons.RiContactsFill />

const profileContent = <ListView type='contactList' options={{ viewFilters: false }}></ListView>

const Wrapper = styled.div`
    width: 300px
    `

const config = [
    {
        key: 'home',
        name: 'Home',
        content: 'Tab content for Home',
        icon: homeIcon,
        disabled: false,
    },
    {
        key: 'profile', name: 'Profile',
        content: profileContent,
        icon: profileIcon,
        disabled: false,
    },
    {
        key: 'contact',
        name: 'Contact',
        content: 'Tab content for Contact',
        icon: contactIcon,
        disabled: true,
    },
];

const ThemeWrapper = styled.div`
    max-width: 250px;
`


const Board = () => {
    const { t, loadTrans } = useTranslation()
    const { ThemeContext } = useTheme()

    const { showToast, ToastComponent } = useToast();

    useEffect(() => {
        loadTrans()
    }, [])


    const saveTheme = (key, value) => {
        saveToLS('Theme', key, value)

    }

    const handleModeChange = (e) => {
        const mode = e.target.value
        saveTheme('themeMode', mode)
        ThemeContext.currentMode = mode
    }

    const handleColorChange = (e) => {
        const color = e.target.value
        saveTheme('themeColor', color)
        ThemeContext.currentColor = color
    }

    const handleStyleChange = (e) => {
        const style = e.target.value
        saveTheme('themeStyle', style)
        ThemeContext.currentStyle = style
    }

    const handleNavModeChange = (e) => {
        const navMode = e.target.value
        saveTheme('themeNavMode', navMode)
        ThemeContext.currentNavMode = navMode
    }

    const handleChange = () => {
        console.log('Change')
    }

    return (
        <>
            <h1>{t('Application.title')}</h1>
            <p>{t('Application.subTitle')}</p>
            <ThemeWrapper>
                <FlexBox>
                    <FlexBox $gap='1rem' $wrap='wrap'>
                        <TextLine $mt='0.5rem' $mb='0rem'>{t('theme.app.mode')}</TextLine>
                        <TextLine $mt='0.5rem' $mb='0rem'>{t('theme.app.color')}</TextLine>
                        <TextLine $mt='0.5rem' $mb='0rem'>{t('theme.app.style')}</TextLine>
                        <TextLine $mt='0.5rem' $mb='0rem'>{t('theme.app.navMode')}</TextLine>
                    </FlexBox>
                    <FlexBox $gap='1rem' $wrap='wrap'>
                        <SimpleSelect
                            currentValue={ThemeContext.currentMode}
                            handleChange={handleModeChange}
                            options={modeMap} width='125px'
                        />
                        <SimpleSelect
                            currentValue={ThemeContext.currentColor}
                            handleChange={handleColorChange}
                            options={colorMap} width='125px'
                        />
                        <SimpleSelect
                            currentValue={ThemeContext.currentStyle}
                            handleChange={handleStyleChange}
                            options={styleMap} width='125px'
                        />
                        <SimpleSelect
                            currentValue={ThemeContext.currentNavMode}
                            handleChange={handleNavModeChange}
                            options={modeMap} width='125px'
                        />
                    </FlexBox>
                </FlexBox>
            </ThemeWrapper>
            <br />
            <Wrapper>
                <NumericInput
                    name='ExampleAmount'
                    $variant="amount"
                    unit="USD"
                    value={10}
                    setValue={handleChange}
                />
            </Wrapper>
            <br />
            <Wrapper>
                <NumericInput
                    name='ExamplePrice'
                    $variant="price"
                    unit="DKK"
                    value={1050.99}
                    setValue={handleChange}
                />
            </Wrapper>
            <br />
            <Wrapper>
                <NumericInput
                    name='ExampleQuantity'
                    $variant="quantity"
                    unit="PCS"
                    value={5}
                    decimals={0}
                    setValue={handleChange}
                />
            </Wrapper>
            <br></br>
            <SwitchExample switchText='isCustomer' />
            <br></br>
            <div style={{ height: '75px', position: 'relative' }}>
                <Loader height={'25px'} size={'25px'} />
                <IconButton $variant='primary' icon={homeIcon} clickAction={() => { showToast('Alert', 'Message for you', 'warning') }}></IconButton>
                <Button onClick={() => { showToast('This is a Toast', 'This is an important Message for you!', 'info') }}>Show Toast</Button>
                <Button onClick={() => { showToast('This is a Toast', 'This is an important Message for you!', 'success') }}>Congrats</Button>
                <Button onClick={() => { showToast('This is a Toast', 'This is an important Message for you!', 'error') }}>Error</Button>
                {ToastComponent}
            </div>
            <br></br>
            <div style={{ background: '#aacc11', paddingBlock: '0.25rem' }}>
                <Reload />

            </div>
            <br></br>
            <br></br>
            <p style={{ fontSize: '16px' }} >App Version 13.11.2024 {ClientVersion}</p>
            <br></br>
            <br></br>
            <MobileNavBar />
        </>
    )
}

const AppBoard = withRouter(Board)
export default AppBoard